// -- basic -- //
import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * news_api
 * 구글,네이버,뉴욕타임즈,블룸버그 등.. 뉴스 수집 데이터 (업계 동향 및 경쟁 정보) // 국내, 해외
 * ─────────────────────────────────────────────────────────────
 */

//-- 뉴스 리스트 데이터 전체 조회 api -- //
export async function news_api(options) {
  let query = null;

  // console.log(options);

  if (options.post_date) {
    query = `post_date=${options.post_date}`;
  }

  if (options.division) {
    // 이미 query가 있으면 &를 추가하고, 없으면 그대로 할당
    query = query ? `${query}&division=${options.division}` : `division=${options.division}`;
  }

  if (options.keyword) {
    // 이미 query가 있으면 &를 추가하고, 없으면 그대로 할당
    query = query ? `${query}&keyword=${options.keyword}` : `keyword=${options.keyword}`;
  }

  if (options.user_email) {
    // 이미 query가 있으면 &를 추가하고, 없으면 그대로 할당
    query = query
      ? `${query}&user_email=${options.user_email}`
      : `user_email=${options.user_email}`;
  }

  try {
    const response = await fetch(`${BASE_URL}/news-base/?${query}`);
    const data = await response.json();

    // console.log(data);
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}

/* ─────────────────────────────────────────────────────────────
 * news_header_api
 * 라벨로 분리된 뉴스 데이터중 하나만 추출해서 헤더 뉴스로 사용
 * ─────────────────────────────────────────────────────────────
 */
//-- 뉴스 리스트 헤더 데이터 조회 api -- //
export async function news_header_api(options) {
  let query = null;

  if (options.post_date) {
    query = `post_date=${options.post_date}`;
  }

  if (options.division) {
    // 이미 query가 있으면 &를 추가하고, 없으면 그대로 할당
    query = query ? `${query}&division=${options.division}` : `division=${options.division}`;
  }

  if (options.keyword) {
    // 이미 query가 있으면 &를 추가하고, 없으면 그대로 할당
    query = query ? `${query}&keyword=${options.keyword}` : `keyword=${options.keyword}`;
  }

  if (options.header) {
    // 이미 query가 있으면 &를 추가하고, 없으면 그대로 할당
    query = query ? `${query}&header=${options.header}` : `header=${options.header}`;
  }

  try {
    const response = await fetch(`${BASE_URL}/news-base/?${query}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}

/* ─────────────────────────────────────────────────────────────
 * news_summary
 * 뉴스 수집 내용을 GPT에게 요약 답변 받은 데이터.
 * ─────────────────────────────────────────────────────────────
 */
export async function news_summary(options) {
  let query = null;

  if (options.post_date && options.user_email) {
    query = `post_date=${options.post_date}&user_email=${options.user_email}`;
  }

  if (options.division) {
    // 이미 query가 있으면 &를 추가하고, 없으면 그대로 할당
    query = query ? `${query}&division=${options.division}` : `division=${options.division}`;
  }

  try {
    const response = await fetch(`${BASE_URL}/news-summary/?${query}`);
    const data = await response.json();
    // console.log('111111111111', data);
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return;
  }
}
