//basic
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

//api
import { google_keywords_api } from '../../../apis/google_keywords_api.js';

//css
import '../../../styles/Reset.css';
import '../../../styles/Common.css';
import '../../../styles/SocialTrend.css';

//img
import arrowUp from '../../../assets/arrow-up-right.png';

/* ───────────────────────────────────────────────────────────────
 * GoogleKeyword
 * - 구글 오늘의 인기검색어 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

export default function GoogleKeyword(props) {
  const [titles, setTitles] = useState([]); //키워드5개용
  const [newsItems, setNewsItems] = useState([]);
  const [twoItems, setTwoItems] = useState([]);
  const [isActive, setIsActive] = useState(0);

  const handleClick = index => {
    setIsActive(index);
    props.onDataFromChild(index);
  };

  const fetchData = useCallback(async () => {
    const result = await google_keywords_api();

    const items = result.rss.channel[0].item;

    const titless = items ? items.slice(0, 10).flatMap(item => item.title) : [];
    setTitles(titless);
    // console.log('titless :: ', titless);

    const newsItemss = titless.map((title, index) => {
      const newsForTitle = items[index]['ht:news_item'].map(newsItem => ({
        newsTitle: newsItem['ht:news_item_title'],
        newsURL: newsItem['ht:news_item_url'],
        newsSnippet: newsItem['ht:news_item_snippet'],
        newsSource: newsItem['ht:news_item_source'],
      }));
      return newsForTitle;
    });

    setNewsItems(newsItemss);
    setTwoItems(newsItemss[0]);
  }, [setTitles]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChangeNews = index => {
    // console.log('키워드선택인덱스 :: ', index);
    if (index === 0) {
      setTwoItems(newsItems[0]);
    } else if (index === 1) {
      setTwoItems(newsItems[1]);
    } else if (index === 2) {
      setTwoItems(newsItems[2]);
    } else if (index === 3) {
      setTwoItems(newsItems[3]);
    } else if (index === 4) {
      setTwoItems(newsItems[4]);
    } else if (index === 5) {
      setTwoItems(newsItems[5]);
    } else if (index === 6) {
      setTwoItems(newsItems[6]);
    } else if (index === 7) {
      setTwoItems(newsItems[7]);
    } else if (index === 8) {
      setTwoItems(newsItems[8]);
    } else if (index === 9) {
      setTwoItems(newsItems[9]);
    } else {
      return null;
    }
  };

  const onClickDeatailNews = (e, link) => {
    e.preventDefault();
    window.open(link, '_blank');
  };

  return (
    <BorderBottom className="googlekeyword_wrap">
      <div className="d-between">
        <Border className="keyword_list_wrap">
          <ul className="keyword_list">
            <BorderBottom className="keyword_list_title d-flex">
              <p className="label2 gray3">순위</p>
              <p className="label2 gray3">키워드</p>
            </BorderBottom>
            <li>
              {titles.map((data, index) => (
                <div
                  key={index}
                  className="d-flex"
                  style={{ paddingTop: '13px' }}
                  onClick={() => handleChangeNews(index)}
                >
                  <p
                    className={`num body3 d-center ${index === isActive ? 'highlight' : ''}`}
                    onClick={() => handleClick(index)}
                  >
                    {index + 1}
                  </p>
                  <Text
                    className={`body2 ${index === isActive ? 'primary' : ''}`}
                    onClick={() => handleClick(index)}
                  >
                    {data}
                  </Text>
                </div>
              ))}
            </li>
          </ul>
        </Border>
        <div className="scroll d-flex">
          <div className="grid">
            {twoItems.length <= 3 ? (
              twoItems.map((twoContents, index) => (
                <BorderBox key={index} className="google_box">
                  <div>
                    <Title
                      href="/"
                      onClick={e => onClickDeatailNews(e, twoContents.newsURL)}
                      className="title2"
                    >
                      {String(twoContents.newsTitle)
                        .replace(/&#39;/g, "'")
                        .replace(/&quot;/g, '"')}
                    </Title>
                    <div className="body2 gray3">
                      {String(twoContents.newsSnippet)
                        .replace(/&#39;/g, "'")
                        .replace(/&quot;/g, '"')
                        .replace(/&nbsp;/g, ' ')}
                    </div>
                    <p className="body3 gray2">출처:{twoContents.newsSource}</p>
                  </div>
                  <div className="d-end">
                    <img
                      src={arrowUp}
                      alt="화살표 이미지"
                      onClick={e => onClickDeatailNews(e, twoContents.newsURL)}
                    />
                  </div>
                </BorderBox>
              ))
            ) : (
              <p>현재 데이터 수집 중입니다.</p>
            )}
          </div>
        </div>
      </div>
    </BorderBottom>
  );
}

const Border = styled.div`
  border-right: 1px solid ${props => props.theme.colors.border};
`;

const BorderBox = styled.div`
  border: 1px solid ${props => props.theme.colors.border};
`;

const BorderBottom = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const Title = styled.a`
  color: ${props => props.theme.colors.titleColor};
`;
