import { useState, useEffect, useCallback } from 'react';

//api
import { news_api } from '../../../apis/news_api.js';
import { stock_api } from '../../../apis/stock_api.js';
import { legislation_summary_api } from '../../../apis/legislation_api.js';
import { patent_api } from '../../../apis/patent_api.js';
import { google_keywords_api } from '../../../apis/google_keywords_api.js';
import { setting_keyword } from '../../../apis/users_api.js';
import { field_keywords_api } from '../../../apis/field_keywords_api.js';

//external library
import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';

// css
import '../../../styles/dashboard.css';

// component
import DashNews from './DashNews';
import DashStock from './DashStock.js';
import DashLegislation from './DashLegislation.js';
import DashPatent from './DashPatent.js';
import DashSocialTrend from './DashSocialTrend.js';
import TagKeyword from '../../common/TagKeyword.js';
import PlusBtn from './PlusBtn.js';
import DashDate from './DashDate.js';

// contexts
import { useAuth } from '../../../contexts/AuthProvider.js';

// common
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';

// img
import news from '../../../assets/news.png';

export default function Dashboard() {
  const { user } = useAuth();
  const { user_email } = user.result;
  const today = formatDateToYYYYmmdd(new Date());

  const tagOption = 'news_keywords_domestic';
  const [tagKeyword, setTagKeyword] = useState({});
  let newsKeywords = '';
  const [newsData, setNewsData] = useState(null);
  const [stockData, setStockData] = useState(null);
  const [currentTab, clickTab] = useState(0);
  const [legislationData, setLegislationData] = useState(null);
  const [patentData, setPatentData] = useState(null);
  const menuArr = [
    { name: '특허', content: <DashPatent className="cont" data={patentData} /> },
    { name: '규제', content: <DashLegislation data={legislationData} className="cont" /> },
  ];
  const selectMenuHandler = index => {
    clickTab(index);
  };

  const [titles, setTitles] = useState([]); //키워드5개용
  const [newsItems, setNewsItems] = useState([]);
  const [twoItems, setTwoItems] = useState([]);

  const [fieldData, setFieldData] = useState([]);
  // const [keywordList, setKeywordList] = useState([]);
  const newColor = '#1EB3B8';
  const colors = ['#2C959C', '#5CCBA3', '#96E587', '#81DBD1', newColor];
  const spiralType = 'archimedean';
  const withRotation = false;

  const fontSizeSetter = datum => fontScale(datum.value);
  const fixedValueGenerator = () => 0.5;

  const fontScale = scaleLog({
    domain: [Math.min(...fieldData.map(w => w.value)), Math.max(...fieldData.map(w => w.value))],
    range: [10, 50],
  });

  function getRotationDegree() {
    const rand = Math.random();
    const degree = rand > 0.5 ? 60 : -60;
    return rand * degree;
  }

  const handleLoad = useCallback(async () => {
    try {
      const keyOptions = {
        collection_date: today,
        division: '국내',
        user_email: user_email,
      };
      const result = await field_keywords_api(keyOptions);
      const updatedData = result.map(item => ({
        text: item.keyword,
        value: item.count,
      }));

      setFieldData(updatedData);
      // setKeywordList(result);
    } catch (error) {
      console.error('Error loading data:', error);
    }
  }, [today, user_email]);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  // 같은 조건으로 뉴스, 키워드 api 호출
  const fetchData = useCallback(async () => {
    const options = {
      division: '국내',
      user_email: user_email,
      keyword: newsKeywords,
      post_date: today,
      stock_collection_date: today,
      summary_date: today,
      header: 'True',
    };

    try {
      const [newsResult, keywordResult, stockResult, legislationResult, patentResult] =
        await Promise.all([
          news_api(options),
          setting_keyword(options),
          stock_api(options),
          legislation_summary_api(options),
          patent_api(options),
        ]);

      setNewsData(newsResult);
      setTagKeyword(keywordResult);
      setStockData(stockResult);
      setLegislationData(legislationResult);
      setPatentData(patentResult);
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    const result = await google_keywords_api();
    const items = result.rss.channel[0].item;
    const titless = items ? items.slice(0, 10).flatMap(item => item.title) : [];
    setTitles(titless);
    // console.log('titless :: ', titless);

    const newsItemss = titless.map((title, index) => {
      const newsForTitle = items[index]['ht:news_item'].map(newsItem => ({
        newsTitle: newsItem['ht:news_item_title'],
        newsSource: newsItem['ht:news_item_source'],
      }));
      return newsForTitle;
    });

    setNewsItems(newsItemss);
    setTwoItems(newsItemss[0]);
  }, [newsKeywords, user_email, today]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="dash_wrap">
      <div className="nav_container">
        <div className="nav">
          <h1 className="title2">Trend Sensing Board</h1>
          <PlusBtn />
        </div>
      </div>

      <div className="dash_container zoom-container">
        <DashDate />

        <div className="dash_box_wrap d-between">
          <div className="dash_box box1">
            <div className="d-flex">
              <img src={news} alt="뉴스 아이콘 이미지" />
              <h2>오늘의 뉴스</h2>
            </div>
            <div className="keyword_wrap d-flex">
              {/* 키워드 */}
              {tagKeyword.length > 0 ? (
                <TagKeyword data={tagKeyword} tag={tagOption} />
              ) : (
                <p className="label2" style={{ width: '100%' }}>
                  No Keywords
                </p>
              )}
            </div>
            <DashNews data={newsData} className="cont" />

            <div className="wordcloud">
              {fieldData && (
                <Wordcloud
                  words={fieldData}
                  width={742}
                  height={300}
                  fontSize={fontSizeSetter}
                  font="Impact"
                  padding={3}
                  spiral={spiralType}
                  rotate={withRotation ? getRotationDegree : 0}
                  random={fixedValueGenerator}
                >
                  {cloudWords =>
                    cloudWords.map((w, i) => (
                      <Text
                        key={i}
                        className={`word size-${w.size}`}
                        fill={colors[i % colors.length]}
                        textAnchor="middle"
                        transform={`translate(${w.x * 0.8}, ${w.y * 0.8}) rotate(${w.rotate})`}
                        fontSize={w.size * 0.8}
                      >
                        {w.text}
                      </Text>
                    ))
                  }
                </Wordcloud>
              )}
            </div>
          </div>

          <div>
            <div className="dash_box box2">
              <div className="d-flex">
                <img src={news} alt="뉴스 아이콘 이미지" />
                <h2>오늘의 증시현황</h2>
              </div>
              <DashStock data={stockData} className="cont" />
            </div>

            <div className="dash_box box3">
              <div className="d-between">
                <div className="d-flex">
                  <img src={news} alt="뉴스 아이콘 이미지" />
                  <h2>오늘의 특허, 규제</h2>
                </div>

                <div>
                  {menuArr.map((el, index) => (
                    <button
                      className={index === currentTab ? 'btn active' : 'btn'}
                      key={index}
                      onClick={() => selectMenuHandler(index)}
                    >
                      {el.name}
                    </button>
                  ))}
                </div>
              </div>
              {menuArr[currentTab].content}
            </div>

            <div className="dash_box box4">
              <div className="d-flex">
                <img src={news} alt="뉴스 아이콘 이미지" />
                <h2>오늘의 소셜 트렌드</h2>
              </div>
              <DashSocialTrend
                news={newsItems}
                title={titles}
                twoItems={twoItems}
                setTwoItems={setTwoItems}
                newsItems={newsItems}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
