//basic
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

//components
import Slider from 'react-slick';

//contexts
import { useAuth } from '../../../contexts/AuthProvider';

//css
import '../../../styles/Reset.css';
import '../../../styles/Common.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/* ───────────────────────────────────────────────────────────────
 * StockList
 * 주식 리스트 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

const StockList = ({ data }) => {
  const { user } = useAuth();
  const user_stock_keywords = user.result.stock_keywords;

  const [selectedStock, setSelectedStock] = useState(null);
  const [filteredStockData, setFilteredStockData] = useState([]);

  const handleStockClick = id => {
    setSelectedStock(id);
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0 && selectedStock === null) {
      const stockArray = Object.values(data).flatMap(arr => arr);

      // const filteredData = uniqueStockData.filter(item => {
      //   // user_stock_keywords 문자열을 쉼표로 분할하여 배열로 만듦
      //   const stockKeywordsArray = user_stock_keywords.split(',');
      //   // 각 키워드에 대해 공백을 기준으로 주식 코드 부분만 추출하여 배열로 만듦
      //   const stockCodes = stockKeywordsArray.map(keyword => keyword.split(' ')[0]);
      //   // item의 company_code가 추출한 주식 코드 배열에 포함되어 있는지 확인하여 필터링
      //   return stockCodes.includes(item.company_code.trim());

      const uniqueStockData = stockArray.filter((currentStock, currentIndex, stock) => {
        const previousMarketIndex = stock.findIndex(
          stock => stock.company_code === currentStock.company_code,
        );
        return currentIndex === previousMarketIndex;
      });

      setFilteredStockData(uniqueStockData);

      setSelectedStock(uniqueStockData.length > 0 ? uniqueStockData[0].id : null);
    }
  }, [data, selectedStock, user_stock_keywords]);

  if (!data) {
    return null; // 데이터가 없는 경우 렌더링 중단
  }

  const selectedStockData = filteredStockData.find(item => item.id === selectedStock);

  if (!selectedStockData) {
    return null;
  }

  const {
    company_name, // 회사 이름
    stock_price, // 주가
    increase_rate, // 전일대비 증감률
    increase_status, // 전일대비 상승, 하락 여부
    previous_price, // 전일 주가
    opening_price, // 시가
    high_price, // 고가
    low_price, // 저가
    trading_volume, // 거래량
    transaction_amount, // 거래대금
    graph_img_url, // 그래프 이미지 URL
    stock_date, // 주식 날짜
    upper_limit_status, // 상한가 여부
    lower_limit_status, // 하한가 여부
  } = selectedStockData;

  //상승 하락 변수
  const increaseStatusText = ['상승', '하락', '보합'];

  // increaseStatus에 따라 아이콘 설정
  let icon = '';

  if (increaseStatusText.indexOf(increase_status) !== -1) {
    if (increaseStatusText.indexOf(increase_status) === 0) {
      icon = '▲';
    } else if (increaseStatusText.indexOf(increase_status) === 1) {
      icon = '▼';
    } else if (increaseStatusText.indexOf(increase_status) === 2) {
      icon = '-';
    }
  }

  // 상승 하락 보합에 따른 클래스명 변경
  let statusClass = 'same';
  if (increase_status === increaseStatusText[0]) {
    statusClass = 'up';
  } else if (increase_status === increaseStatusText[1]) {
    statusClass = 'down';
  }

  let sliderSettings = {
    dots: false,
    speed: 500,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  function getTotalCharacterCount(stockData) {
    // console.log(stockData);
    return stockData.reduce((total, stock) => {
      return total + stock.company_name.length;
    }, 0);
  }

  const totalCharacterCount = getTotalCharacterCount(filteredStockData);

  if (filteredStockData.length === 1) {
    sliderSettings = {
      ...sliderSettings,
      infinite: false,
      slidesToShow: 1,
      variableWidth: false,
    };
  } else if (filteredStockData.length === 2) {
    sliderSettings = {
      ...sliderSettings,
      infinite: false,
      slidesToShow: 2,
      variableWidth: false,
    };
  } else if (filteredStockData.length >= 3) {
    if (totalCharacterCount > 22) {
      sliderSettings = {
        ...sliderSettings,
        infinite: true,
        slidesToShow: 3,
        variableWidth: true,
      };
    } else {
      sliderSettings = {
        ...sliderSettings,
        infinite: false,
        slidesToShow: filteredStockData.length,
        variableWidth: false,
      };
    }
  } else {
    sliderSettings = {
      ...sliderSettings,
      infinite: true,
      slidesToShow: 3,
      variableWidth: true,
    };
  }

  return (
    <div className="cont_box">
      <div className="slider-container">
        <Slider {...sliderSettings}>
          {filteredStockData.map(item => (
            <div key={item.id}>
              <button
                onClick={() => handleStockClick(item.id)}
                className={`slide_btn d-flex label3 ${selectedStock === item.id ? 'selected' : ''}`}
              >
                {item.company_name}
              </button>
            </div>
          ))}
        </Slider>
      </div>

      {selectedStock !== null && (
        <div key={selectedStock} className="market_cont">
          <div className="cont1">
            <Text className="label2">{company_name}</Text>
            <Title>{stock_price}원</Title>
            <p className={`${statusClass} body2`}>
              <span className="gray3">전일대비</span> {icon} {increase_rate}
            </p>
          </div>

          <div className="cont2 d-flex">
            <div className="border-right">
              <SubText className="body3">
                전일<span className="basic">{previous_price}원</span>
              </SubText>
              <SubText className="body3">
                시가 <span className="up">{opening_price}원</span>
              </SubText>
            </div>

            <div className="border-right">
              <SubText className="body3">
                고가 <span className="up">{high_price}원</span>
                <span style={{ paddingLeft: '0px' }}> (상한가 {upper_limit_status}원)</span>
              </SubText>
              <SubText className="body3">
                저가 <span className="down">{low_price}원</span>
                <span style={{ paddingLeft: '0px' }}> (하한가 {lower_limit_status}원)</span>
              </SubText>
            </div>

            <div>
              <SubText className="body3">
                거래량 <span className="basic">{trading_volume}</span>
              </SubText>
              <SubText className="body3">
                거래대금 <span className="basic">{transaction_amount}</span>
              </SubText>
            </div>
          </div>

          <img src={graph_img_url} alt="주식그래프" className="cont3" />

          <span className="body3 d-end gray3">{stock_date}</span>
        </div>
      )}
    </div>
  );
};

export default StockList;

const SubText = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;
const Title = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;
